<template>
  <div class="loacl-index">
      <div class="loacl-index-left">
          <!-- <input type="file" @change="getBigectURL($event)" webkitdirectory directory multiple ref="refFile"/> -->
          <div class="loacl-index-label">
              <div>
                    <label for="" class="loacl-label">本地文件</label>
                    <el-button type="primary" class="fileImport" id="fileImport" @click="clickLoad">选择文件</el-button>
                    <!-- <input type="file" id="files" ref="refFile" webkitdirectory directory multiple style="display: none" @change="getBigectURL($event)" > -->
                    <input id="file" type="file" ref="refFile" webkitdirectory directory multiple style="display: none"  @change="fileImport($event)"/>
              </div>
              <div>
                    <label for="" class="loacl-label">文件时间</label>
                    <ele-calendar
                        :render-content="renderContent"
                        :data="datedef"
                        :prop="prop"
                        disabledDate: true
                    ></ele-calendar>
              </div>
          </div>
      </div>
      <div class="loacl-index-right">
          <el-tabs v-model="editableTabsValue" type="card"  @tab-click="tabClick($event)">
            <el-tab-pane label="视频文件" name="1" class="item-title">
                <div class="vieo-main">
                    <div v-for="(item, index) in videoSrcData" :key= index class="item-video">
                        <!-- <video @canplay="getDuration" @timeupdate="updateTime" ref="video" :src="item.src" controls="controls" width="284" height="167"></video> -->
                        <video :src="item.src" controls="controls" width="284" height="167"></video>
                        <div class="item-video-text">开始时间：{{ item.time }}</div>
                        <div class="item-video-text">视频大小：{{ item.size }}</div>
                        <div class="item-video-text item-video-click" :style="{'color': (!item.status ? '#0575E6':'#409EFF;')}"  @click="detailsClick(item)"><i class="el-icon-search" style="margin-right: 5px"></i>查看详情</div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane
                v-for="(item, index) in editableTabs"
                :key="item.name"
                :label="item.urlName"
                :name="item.name"
            >
                <!-- 地图轨迹 -->
                <div class="trajectory-main-map">
                    <div class="main-map" :id="item.urlName">
                      <div class="map-video">
                        <video :src="item.src" ref="videoRef" controls="controls" width="400" height="225" autoplay id="videoPlay"></video>
                      </div>
                    </div>
                    <div class="main-table">
                        <div class="table-tabs">
                            <div v-for="(item, idx) in ['停车点', '报警列表']" :key="idx" class="curp"
                                :class="{'tabs-active': tabsActive===item}"
                                @click="clickTable(item)">{{item}}
                            </div>
                        </div>
                        <div class="table">
                            <ayl-table :table="stopTable" v-show="tabsActive==='停车点'" style="width: 100%;" />
                            <ayl-table :table="alarmTable" v-show="tabsActive==='报警列表'" style="width: 100%;"/>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
          </el-tabs>
        
      </div>

      <!-- 查看附件 -->
    <el-dialog title="查看附件" :visible.sync="dialogFormVisible">
      <div class="attachment-main">
        <div class="left-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" style="width: 100%"
                 v-if="videoIdx === 1"></video>
          <img :src="url===''? playerOptions.images[0]:url" class="images" v-image-preview v-else>
        </div>
        <div class="right-img">
          <video :src="videoUrl" type="video/mp4" controls="controls" preload="auto" class="images"
                 @click="videoItem(1)"></video>
          <img v-for="(item, idx) in playerOptions.images" :key="idx" @click="chg(idx,0)" :src="item" class="images">
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  const onLineIcon = require('../../../../assets/images/map-icon (3).png')
  const _s = require('../../../../assets/images/starting.png')
  const _e = require('../../../../assets/images/end.png')
  const _st = require('../../../../assets/images/tingche.png')
  const _Ar = require('../../../../assets/images/bj.png')
// import md5 from 'js-md5'
//import muxjs from 'mux.js'
import muxjs from 'newmux'
import eleCalendar from 'ele-calendar'
import 'ele-calendar/dist/vue-calendar.css' 
import { async } from 'q';
export default {
    components: {
        eleCalendar
    },
    data(){
        const vm = this
        return {
            selectedFile: [],
            objectURL: [],
            videoSrcData: [],
            videoSrc: [],
            videoSrc1: [],
            // videoSrcItem:'',
            currentTime: null,
            videoTimeData: [],
            datedef:[],
            prop:'date', //对应日期字段名
            deviceName: null,    //devicename

            //切换参数
            editableTabsValue: '1',
            editableTabs: [],
            tabIndex: 1,

            tabsActive: '停车点',
        // 地图数据
        map: null,
        center: null,
        mapMarker: [],
        mapStopMarker: [],
        mapAlarmMarker: [],
        polyline: null,
        // 速度
        speed: null,
        speed_f: null,

        // 停车点列表
        stopTable: {
          hidePagination: true,
          api: vm.$api.localListParkingSpot,
          query: {
            filename: null,
            deviceName: null,
          },
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '车牌号',
            key: 'plateNumber',
          }, {
            width: '90px',
            title: '驾驶员姓名',
            key: 'driverName',
          }, {
            title: '所属公司',
            key: 'carUnit',
            showTooltip: true,
          }, {
            title: '总里程',
            key: 'mileage',
          }, {
            title: '停车位置',
            key: 'position',
            showTooltip: true,
          }, {
            title: '开始时间',
            key: 'stopStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '结束时间',
            key: 'stopEndTime',
            filter: 'str2ymdhm',
            showTooltip: true,
          }, {
            title: '停车时长',
            key: 'stopTime',
          }, {
            title: '操作',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '停车点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },
        // 报警点列表
        alarmTable: {
          hidePagination: true,
          api: vm.$api.localListAlarm,
          query: {
            filename: null,
            deviceName: null,
          },
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          }, {
            title: '车牌号',
            key: 'plateNumber',
            width: '100px',
          }, {
            title: '驾驶员姓名',
            key: 'driverName',
            width: '90px',
          }, {
            title: '报警类型',
            key: 'alarmIdentifier',
            showTooltip: true,
          }, {
            title: '报警等级',
            key: 'alarmType',
            showTooltip: true,
          }, {
            title: '报警开始时间',
            key: 'alarmStartTime',
            filter: 'str2ymdhm',
            showTooltip: true,
            width: '110px',
          }, {
            title: '报警位置',
            key: 'location',
            showTooltip: true,
          }, {
            title: '处理状态',
            key: 'dealStatus',
          }, {
            title: '处理人',
            key: 'dealUserName',
          }, {
            title: '附件',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': ctx.row.attache === 1,
                  },
                  on: {
                    click: vm.goPreview.bind(this, ctx.row)
                  }
                }, ctx.row.attache === 0 ? '无附件' : '预览')
              ])
            }
          }, {
            title: '操作',
            width: '180px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.goDetail.bind(this, ctx.row)
                  }
                }, '查看详情'),
                h('span', {
                  class: {
                    'table-view': true,
                  },
                  on: {
                    click: vm.viewPosition.bind(this, ctx.index, '报警点')
                  }
                }, '查看位置'),
              ])
            }
          }]
        },
        //公共参数
        newFilename: null,

        deviceData: null,
        coordinateList: [],
        stopPointList: [],
        alarmPointList: [],


        dialogFormVisible: false,
        url: '',
        videoUrl: '',
        videoIdx: 1,
        playerOptions: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [],
            images: [],
            poster: '', //你的封面地址
            notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: false,
                fullscreenToggle: true  //全屏按钮
            }
          },
        }
    },
    methods:{
        renderContent(h,parmas) {
            const loop = data =>{
              return (
                data.defvalue.value ? (<div style="min-height:60px;background-color: #409EFF;color:#fff"><div>{data.defvalue.text}</div> 
                <span> </span>
                </div>) : <div>{data.defvalue.text}</div>
              )
           }
           return (
            <div  style="min-height:60px">
             {loop(parmas)}
            </div>
            );
        },

        //截取日期
        SubstringValue(e){
            let newVal = ''
            newVal = e.substring(9,13) + "-" + e.substring(13,15) + "-" + e.substring(15,17)
            return(newVal)
        },
        //截取文件前四位
        maineValue(e){
            let newManeVal = ''
            newManeVal = e.substring(0,4)
            return(newManeVal)
        },
         //截取日期时分秒
        timeValue(e){
            let newTimeVal = ''
            newTimeVal = e.substring(9,13) + "-" + e.substring(13,15) + "-" + e.substring(15,17) + '  ' + e.substring(17,19) + ':' + e.substring(19,21) + ':' + e.substring(21,23)
            return(newTimeVal)
        },
        //获取文件大小
        sizeValue(e){
            var a = e / Math.pow(1024,2)
            var size = Math.floor(a * 10) / 10 + "MB" // 字节转化为MB
            return(size)
        },

       async tabClick(event){
          if(event.label ==='视频文件') return
           await this.getStopTableList(event.label,this.deviceName)
           await this.getAlarmTableList(event.label,this.deviceName)
       },

        //查看详情
        async detailsClick(val){
          console.log(val)
            this.newFilename = val.name
            for(let i =0; i<this.editableTabs.length; i++){
              if(val.name ===this.editableTabs[i].urlName ){
                this.$message({
                    message: '该详情已打开！',
                    type: 'warning'
                });
                return
              }
            }
            let newTabName = ++this.tabIndex + '';
            // this.readAsArrayBuffer(val.file);
            
            //await this.readAsArrayBuffer(val.file);
            await this.editableTabs.push({
                title:  val.time,
                name: newTabName,
                urlName: val.name,
                status: val.status,
                src: val.src,
            });
            //console.log(this.videoSrcItem)
            var vdo = document.getElementById("videoPlay")
            //this.$refs.videoRef.src = this.videoSrcItem
            vdo.load()
            if(this.editableTabs.length > 3){
                //console.log(this.editableTabs)
                this.editableTabs[0].status = !this.editableTabs[0].status
                let newArr = this.editableTabs.slice(0);
                newArr.splice(0, 1);
                this.editableTabs = newArr
                // console.log(this.editableTabs)
            }
            this.editableTabsValue = newTabName;
            this.$nextTick(() =>{
                this.initMap(val.name)
            })

              //获取deviceid
            this.deviceData = await this.$api.getDeviceIdByFilename({
                filename: val.name,
                deviceName: this.deviceName
            })
            const data = await this.$api.GetDeviceHistoryUsage({
                deviceId: this.deviceData.deviceId,
                startTime: this.deviceData.startTime,
                endTime: this.deviceData.endTime,
            })

            this.coordinateList = data.coordinateList
            this.stopPointList = data.stopPointList
            this.alarmPointList = data.alarmPointList
            this.initCoordinate(this.coordinateList)
            this.initStopPoint(this.stopPointList)
            this.initAlarmPoint(this.alarmPointList)
            await this.getStopTableList(val.name,this.deviceName)
            await this.getAlarmTableList(val.name,this.deviceName)

            this.startAnimation()
        },

        // 初始化地图
        initMap (e) {
            this.map = new AMap.Map( e, {
                center: this.center,
                resizeEnable: true,
                zoom: 13,
            })
        },

        transferFormat (data,name,size,file) {
            let that = this
            // 将源数据从ArrayBuffer格式保存为可操作的Uint8Array格式
            var segment = new Uint8Array(data); 
            var combined = false;
            // 接收无音频ts文件，OutputType设置为'video'，带音频ts设置为'combined'
            var outputType = 'video';
            var remuxedSegments = [];
            var remuxedBytesLength = 0;
            var remuxedInitSegment = null;

            // remux选项默认为true，将源数据的音频视频混合为mp4，设为false则不混合
            var transmuxer = new muxjs.mp4.Transmuxer({remux: false});
            
            // 监听data事件，开始转换流
            transmuxer.on('data', function(event) {
                // console.log('data event fired!!!');
                // console.log(event);
                if (event.type === outputType) {
                remuxedSegments.push(event);
                remuxedBytesLength += event.data.byteLength;
                remuxedInitSegment = event.initSegment;
                }
            });
            // 监听转换完成事件，拼接最后结果并传入MediaSource
            transmuxer.on('done', function () {
                var offset = 0;
                var bytes = new Uint8Array(remuxedInitSegment.byteLength + remuxedBytesLength)
                bytes.set(remuxedInitSegment, offset);
                offset += remuxedInitSegment.byteLength;

                for (var j = 0, i = offset; j < remuxedSegments.length; j++) {
                bytes.set(remuxedSegments[j].data, i);
                i += remuxedSegments[j].byteLength;
                }
                remuxedSegments = [];
                remuxedBytesLength = 0;
                // 解析出转换后的mp4相关信息，与最终转换结果无关
                let vjsParsed = muxjs.mp4.tools.inspect(bytes);
                // console.log('transmuxed', vjsParsed);

                that.prepareSourceBuffer(combined, outputType, bytes,name,size,file);
            });
            // push方法可能会触发'data'事件，因此要在事件注册完成后调用
            transmuxer.push(segment); // 传入源二进制数据，分割为m2ts包，依次调用上图中的流程
            // flush的调用会直接触发'done'事件，因此要事件注册完成后调用
            transmuxer.flush(); // 将所有数据从缓存区清出来
        },

        prepareSourceBuffer (combined, outputType, bytes, name, size,file) {
            var buffer;
            let mediaSource = new MediaSource();
            var videoSrcItem = URL.createObjectURL(mediaSource);
           // console.log(file.lastModifiedDate)
           // console.log(file.lastModified)
            this.videoSrc.push({
                src: videoSrcItem,
                name: name,
                size: this.sizeValue(size),
                time: this.timeValue(name),
                date:this.SubstringValue(name),
                status: false,
                file: file,
            })
            this.videoSrcData = this.videoSrc.concat(this.videoSrc1)
            this.datedef = this.videoSrcData
            //this.totalNum = this.videoSrc.length
            //this.newVideoSrc = this.videoSrc.slice(0,this.pageSize)
            // 转换后mp4的音频格式 视频格式
            var codecsArray = ["avc1.64001f", "mp4a.40.5"];
            
            mediaSource.addEventListener('sourceopen', function () {
                // MediaSource 实例默认的duration属性为NaN
                mediaSource.duration = 0;
                // 转换为带音频、视频的mp4
                if (combined) {
                buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + 'avc1.64001f,mp4a.40.5' + '"');
                } else if (outputType === 'video') {
                // 转换为只含视频的mp4
                buffer = mediaSource.addSourceBuffer('video/mp4;codecs="' + codecsArray[0] + '"');
                } else if (outputType === 'audio') {
                // 转换为只含音频的mp4
                buffer = mediaSource.addSourceBuffer('audio/mp4;codecs="' + (codecsArray[1] ||codecsArray[0]) + '"');
                }
            
                buffer.addEventListener('updatestart', this.logevent);
                buffer.addEventListener('updateend', this.logevent);
                buffer.addEventListener('error', this.logevent);
                buffer.appendBuffer(bytes);
                // 自动播放
                // video.play();
            });
        },
        logevent (event) {
            //console.log(event);
        },
        /**
         * 读取成二进制
         */
        readAsArrayBuffer(file) {
            var name = file.name;//读取选中文件的文件名
            var size = file.size;//读取选中文件的大小
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (event) => {
                const binary = fileReader.result
                // 处理二进制数据
                this.transferFormat(binary,name,size,file);
            }
        },
        clickLoad() {
            this.$refs.refFile.dispatchEvent(new MouseEvent('click'))
        },

        fileImport() {
            this.videoSrcData = []
            this.datedef = []
            this.videoSrc = [],
            this.videoSrc1 = [],
            this.editableTabs = [],
            //获取读取我文件的File对象
            this.selectedFile = event.target.files
            for(let i = 0; i < this.selectedFile.length; i++){
                if(this.selectedFile[i].name.endsWith('ts') && this.maineValue(this.selectedFile[i].name) =='main'){
                    this.readAsArrayBuffer(this.selectedFile[i]);
                }else if(this.selectedFile[i].name.endsWith('mp4')){
                    this.objectURL = URL.createObjectURL(this.selectedFile[i]);
                    this.videoSrc1.push({
                        src: this.objectURL,
                        name: this.selectedFile[i].name,
                        date:this.SubstringValue(this.selectedFile[i].name),
                        time: this.timeValue(this.selectedFile[i].name),
                        size: this.sizeValue(this.selectedFile[i].size),
                        status: false,
                    })
                }else if(this.selectedFile[i].name.endsWith('txt')){
                  const file = this.selectedFile[i]
                  const reader = new FileReader()
                  reader.onload = e => this.$emit("load", this.dealNum(e.target.result))
                  reader.readAsText(file)
                }
                // console.log(this.videoSrc1)
            }
        },
        dealNum(item){
          this.deviceName = item
          //console.log(this.deviceName)
        },

      // 绘制轨迹
      initCoordinate (c) {
        // 绘制起点
        this.mapMarker[0] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: _s,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制终点
        this.mapMarker[1] = new AMap.Marker({
          map: this.map,
          position: c[c.length - 1],
          icon: _e,
          offset: new AMap.Pixel(-26, -24),
        })
        // 绘制车辆
        this.mapMarker[2] = new AMap.Marker({
          map: this.map,
          position: c[0],
          icon: onLineIcon,
          offset: new AMap.Pixel(-10, -15),
          autoRotation: true,
          // angle: 180,
        })
        // 绘制线路
        this.polyline = new AMap.Polyline({
          map: this.map,
          path: c,
          showDir: true,
          isOutline: true,
          outlineColor: '#cccccc',
          strokeColor: '#1e9eea',
          strokeWeight: 8,
          lineJoin: 'round',
        })
        this.map.setFitView()
        // 获取轨迹的总里程
        const mileage = Math.round(this.polyline.getLength() / 1000)
        /*
        * 根据设备上报的点来计算速度
        * 正常播放：点与点间隔时间为1s；
        * 快速播放：点与点间隔时间为0.25s.
        * */
        const num = (c.length / 60 / 60).toFixed(3)
        this.speed = Math.round(mileage / num)

        const num_f = (c.length * 0.25 / 60 / 60).toFixed(3)
        this.speed_f = Math.round(mileage / num_f)

        // console.log(this.speed, this.speed_f)

        this.mapMarker[2].moveAlong(c, this.speed)
      },
      // 绘制停车点
      initStopPoint (s) {
        s.forEach((e, i) => {
          this.mapStopMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            icon: _st,
          })
        })
      },
      // 绘制报警点
      initAlarmPoint (a) {
        a.forEach((e, i) => {
          this.mapAlarmMarker[i] = new AMap.Marker({
            map: this.map,
            position: e,
            offset: new AMap.Pixel(-10, -26),
            icon: _Ar,
          })
        })
      },
      // 请求停车点
      async getStopTableList (val,device) {
        this.stopTable.query.filename = val
        this.stopTable.query.deviceName = device
        await this.$search(this.stopTable)
      },
      // 请求报警点
      async getAlarmTableList (val,device) {
        this.alarmTable.query.filename = val
        this.alarmTable.query.deviceName = device
        await this.$search(this.alarmTable)
      },
      // 开始播放
      startAnimation () {
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed)
        // this.mapMarker[2].resumeMove(coordinate, 500)
      },
      // 暂停播放
      stopAnimation () {
        this.mapMarker[2].pauseMove()
      },
      // 继续
      initAnimation () {
        this.mapMarker[2].resumeMove()
      },
      // 快速
      fastAnimation () {
        this.mapMarker[2].moveAlong(this.coordinateList, this.speed_f)
      },
      // 查看位置
      viewPosition (i, v) {
        v === '报警点' ? Animation(this.map, this.mapAlarmMarker, i) : Animation(this.map, this.mapStopMarker, i)

        function Animation (map, markerArr, index) {
          const lng = markerArr[index].getPosition()
          map.setZoomAndCenter(14, [lng.lng, lng.lat])
          markerArr[index].setAnimation('AMAP_ANIMATION_BOUNCE')
          setTimeout(() => {
            markerArr[index].setAnimation('AMAP_ANIMATION_NONE')
          }, 3500)
        }

      },
      goDetail (val) {
        this.$router.push({
          path: '/safety-center/alarm-management/alarmDisposeDetails',
          query: {
            deviceId: val.deviceId,
            alarmStartTime: val.alarmStartTime,
            alarmType: val.alarmType,
            tenantId: val.tenantId,
            alarmName: val.alarmIdentifier
          }
        })
      },
      
      clickTable (e) {
        if (this.tabsActive === e) return
        this.tabsActive = e
        if (!this.deviceData.deviceId) return
        //e === '停车点' ? this.getStopTableList(this.newFilename) : this.getAlarmTableList(this.newFilename)
      },
      async goPreview (val) {
        if (val.attache === 0) return
        this.dialogFormVisible = true
        let videoData = await this.$api.findFileByPrefix({
          tenantId: val.tenantId,
          attacheKey: val.attacheKey
        })
        this.playerOptions.images = videoData[0].images
        this.playerOptions.sources = videoData[0].sources
        this.videoUrl = this.playerOptions.sources[0].src
      },
      chg: function (ind, val) {
        this.url = this.playerOptions.images[ind]
        this.videoIdx = val
      },
      videoItem (val) {
        this.videoIdx = val
      }
    },
    watch:{
        "selectedFile": function(){
            // window.URL.revokeObjectURL(this.objectURL);
            // window.URL.revokeObjectURL(this.videoSrcItem);
        }
    },
    async mounted () {
    //   this.initMap()
    }
}
</script>

<style lang='sass' scoped>
.loacl-index
    .loacl-index-left
        width: 450px
        display: inline-block
        .loacl-index-label
            padding: 30px 20px
            .loacl-label
                font-size: 14px
                font-weight: 400
                color: rgba(51,51,51,1)
                line-height: 46px
            .fileImport
                margin: 0 20px
    .loacl-index-right
        width: calc(100% - 470px)
        height: 100%
        display: inline-block
        vertical-align: top
        margin: 10px
        background: rgba(245,245,245,1)
        position: relative
        .trajectory-main-map
            width: 100%
            .main-map
                height: 482px 
                position: relative

                .map-video
                  position: absolute
                  bottom: 0px
                  left: 0px
                  z-index: 2
                  width: 400px
                  height: 225px
                  background: rgba(255, 255, 255, 1)
                  box-shadow: 0 1px 5px 0 rgba(8, 88, 169, 0.3)
                  border-radius: 4px

                .map-control
                    position: absolute
                    bottom: 10px
                    right: 10px
                    z-index: 2
                    width: 200px
                    height: 126px
                    background: rgba(255, 255, 255, 1)
                    box-shadow: 0 1px 5px 0 rgba(8, 88, 169, 0.3)
                    border-radius: 4px
                    padding: 15px 15px 14px

            .main-table
                margin-top: 10px
                height: calc(100% - 482px - 10px)

                .table-tabs
                    width: 100%
                .tabs-active
                    color: $-color-primary-2

                div
                    display: inline-block
                    padding: 10px 14px
                    color: $-font-color-2
                    position: relative
                    font-size: $fontX

                    &:hover
                        color: $-color-primary-2

                    & + div:after
                        content: ' '
                        position: absolute
                        left: 0
                        width: 1px
                        height: 16px
                        background-color: $grayShallow3

                .table
                    width: 100%
                    height: calc(100% - 38px)
        /deep/ .el-tabs__header
                    border-bottom: 1px solid $grayShallow3
                    padding-left: 0px
                    margin-bottom: 0
        .item-title
            font-size: 16px
            color: rgba(51,51,51,1)
            line-height: 64px
            margin-left: 20px
        .vieo-main
            width: 100%
            height: 640px
            overflow: auto
            .item-video
                width: 284px
                margin: 0 10px
                height: 250px
                display: inline-block
                .item-video-text
                    line-height: 24px
                    color: rgba(51,51,51,1)
                    font-size: 14px
                    margin-left: 10px
                .item-video-click
                    //color: #0575E6
                    cursor: pointer

        .pagination
            height: 50px
            position: absolute
            bottom: 0
            right: 20px
    /deep/ .el-form-item
      width: 100%

    .attachment-main
      width: 100%

      .right-img
        width: 20%
        height: 520px
        display: inline-block
        vertical-align: top
        margin-left: 2%
        overflow-y: auto

        .images
          width: 100%

      .left-img
        width: 78%
        height: 520px
        display: inline-block

        .images
          width: 100%
          height: 100%

      .class
        display: block

      .show
        display: none

/deep/.el-date-table-calendar td.current:not(.disabled) 
    color: #606266
    background-color: #fff
</style>